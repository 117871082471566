/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
var $mainNav = $('.offscreen-nav');
var $navToggle = $('.nav-toggle');
var $body = $('body');
var navState = 'closed';

$navToggle.click(function(e) {
    e.preventDefault();

    if(navState === 'closed') {
      openNav();
    } else {
      closeNav();
    }
});

function closeNav() {
  $navToggle.removeClass('active');
  $body.removeClass('nav-overlay');
  $mainNav.removeClass('open');
  $navToggle.children('.hamburger').removeClass('active');
  Foundation.Motion.animateOut($mainNav, 'fade-out');
  navState = 'closed';
}

function openNav() {
  $navToggle.addClass('active');
  $body.addClass('nav-overlay');
  $mainNav.addClass('open');
  $navToggle.children('.hamburger').addClass('active');
  Foundation.Motion.animateIn($mainNav, 'fade-in');
  navState = 'open';
}


/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/

const path = location.pathname.substring(1);
if (path) {
  $(`nav a[href$="${path}"]`).parent().addClass('selected');
}

if(path.split('/')[0] == 'blog') {
  $('nav a[href$="/blog"]').parent().addClass('selected');
}


/* ==================================================
Sliders:

Flickity carousel setup and initialization
===================================================*/

var flkty = new Flickity( '.gallery-slider', {
  arrowShape: 'M62.627 1.499L6.88 46.096a5 5 0 0 0 0 7.808l55.747 44.597a5 5 0 0 0 8.123-3.904V5.403A5 5 0 0 0 62.627 1.5z',
  cellAlign: 'left',
  contain: true,
  lazyLoad: 1,
  pageDots: false,
  wrapAround: true
});

var flktymaps = new Flickity( '.map-gallery-slider', {
  arrowShape: 'M62.627 1.499L6.88 46.096a5 5 0 0 0 0 7.808l55.747 44.597a5 5 0 0 0 8.123-3.904V5.403A5 5 0 0 0 62.627 1.5z',
  cellAlign: 'left',
  contain: true,
  lazyLoad: 1,
  pageDots: false,
  wrapAround: true
});

/* ==================================================
Netlify AJAX Form submission:
Grabs action attribute from form and submits to
Netlify via AJAX, then fades out form and displays
thanks message
===================================================*/
var $contactFormWrap = $('.footer-form');
var $contactFormMessage = $('#form-thanks');
var $contactFormContainer = $('#form-container');
var $contactForm = $('#contact-form');

if($contactFormWrap.length) {
  $('#contact-form').on("submit", function(event) {
    event.preventDefault();
    $.post($contactForm.attr("action"), $contactForm.serialize()).then(function() {
      $contactFormContainer.addClass('hide');
      $contactFormMessage.fadeIn();
    });
  });
}


/* ==================================================
Magnific:

Magnific is used for lightbox style popups.

.image-link is for displaying larger versions of
images from a a link.

.video-link is for displaying videos from a link.
Common providers like Youtube and Vimeo will
stop playing when window is closed.
===================================================*/

// $('.image-link').magnificPopup({ type: 'image' });
// $('.video-link').magnificPopup({ type: 'iframe' });

/* ==================================================
Smooth Scrolling :

Smooth scroll to hash element
===================================================*/

// Select all links with hashes
$('nav a[href^="#"]').on('click',function (e) {
    e.preventDefault();
    closeNav();
    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
        window.location.hash = target;
    });
});

//Utilities
$(function(){
  $('html').removeClass('no-js');
});